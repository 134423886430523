import dynamic from "next/dynamic";
import Image from "next/image";

export const Lottie = dynamic(() => import("lottie-react"), {
  ssr: false,
  loading: () => (
    <Image
      width={600}
      height={600}
      priority
      src="./connect-first-frame.svg"
      alt={"electra-power-connect"}
    />
  ),
});
