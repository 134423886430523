"use client";

import { Lottie } from "@/components/Layout/Lottie";
import { layoutVar, userVar } from "@/store";
import colors from "@/theme/colors";
import sizes from "@/theme/sizes";
import theme from "@/theme/theme";
import { Global, css } from "@emotion/react";
import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Image from "next/image";
import { useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { Pages } from "../../../common/pages.modal";
import animationData from "./lottie-connect.json";

const StyledWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.black};
`;

const BoxWrapper = styled(Box)`
  width: 100%;
  max-height: 1200px;
  max-width: 600px;
  img {
    max-width: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 200px;
  ${theme.breakpoints.down("md")} {
    & img {
      width: 180px;
      height: 180px;
    }
  }
  ${theme.breakpoints.down("sm")} {
    & img {
      width: 120px;
      height: 120px;
    }
  }
`;
const LogoWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-top: 100px;
  max-height: 200px;

  ${theme.breakpoints.down("lg")} {
    max-width: 400px;
  }
  ${theme.breakpoints.down("md")} {
    max-width: 300px;
  }
  ${theme.breakpoints.down("xs")} {
    max-width: 220px;
  }
  img {
    max-width: 100%;
  }
`;
type Props = {
  hideHeader?: boolean;
};

export default function SplashPage({ hideHeader }: Props) {
  const router = useRouter();
  const searchParams = useSearchParams();
  layoutVar({ ...layoutVar(), hideHeader: !!hideHeader });

  useEffect(() => {
    const search = searchParams?.get("packege") || searchParams?.get("package");
    if (search) {
      userVar({ ...userVar(), packageChosen: search });
    }

    const tick = setTimeout(() => {
      router.push(`/${Pages.onBoarding}`);
    }, 1500);
    return () => clearTimeout(tick);
  }, []);

  return (
    <StyledWrapper>
      <Global
        styles={css`
          body {
            overflow: hidden;
            background-color: ${colors.black};
          }
        `}
      />
      <ImageWrapper>
        <Image
          src="./circle_bg.svg"
          alt="ספלאש לוגו"
          width={300}
          height={300}
          style={{ left: 0, top: -50, position: "absolute" }}
        />
      </ImageWrapper>
      <LogoWrapper>
        <Image
          src="./logo.svg"
          alt="לוגו אלקטרה פוואר"
          width={sizes.images.logo_bg.width}
          height={sizes.images.logo_bg.height}
        />
        <Typography variant="h1">הנה זה בא באנרגיה טובה</Typography>
      </LogoWrapper>
      <BoxWrapper>
        <Lottie animationData={animationData} />
      </BoxWrapper>
      <ImageWrapper>
        <Image
          src="./circle_bg_reverse.svg"
          alt="ספלאש לוגו"
          width={300}
          height={300}
          style={{ right: 0, bottom: 50, position: "absolute" }}
        />
      </ImageWrapper>
    </StyledWrapper>
  );
}
